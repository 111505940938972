import { z } from 'zod'

export const platformRoles = [
  'techAdmin',
  'businessAdmin',
  'insuranceAdmin',
  'brokerAdmin',
  'businessSpecialist',
  'claimsManagement',
] as const

export const partnerRoles = ['broker'] as const

export const clientRoles = ['prospect', 'customer', 'anyone'] as const

export const platformRoleSchema = z.enum(platformRoles)
export const partnerRoleSchema = z.enum(partnerRoles)
export const clientRoleSchema = z.enum(clientRoles)
export const roleSchema = z.enum([...platformRoles, ...partnerRoles, ...clientRoles])

/** Roles are used to define the access level of a user, but this is transient and will be replaced by permissions in the next iteration of the RAM project
 */
export type Role = z.infer<typeof roleSchema>
export type PlatFormRole = z.infer<typeof platformRoleSchema>
export type PartnerRole = z.infer<typeof partnerRoleSchema>
export type ClientRole = z.infer<typeof clientRoleSchema>

export const roleDocumentation: Record<Role, string> = {
  businessAdmin:
    'Ce rôle déstiné à tous les Orussiens et Orussiennes permet d’effectuer les opérations nécessaires et sensibles au jour le jour, pour tous les clients.',
  businessSpecialist:
    'Ce rôle déstiné à tous les Orussiens et Orussiennes permet d’effectuer les opérations nécessaires au jour le jour, pour tous les clients.',
  brokerAdmin:
    "Ce rôle donne tous les droits de 'businessSpecialist', donne accès à l'édition des organisations et de leurs membres ainsi que le changement d'ownership d'une souscription",
  insuranceAdmin:
    'Ce rôle donne tous les droits de "businessAdmin" et donne accès en plus a certaines actions un process spécial, comme la validation des documents RCDA par exemple.',
  claimsManagement: 'Ce rôle est destiné à gérer des sinistres, potentiellement par des personnes externes',
  techAdmin:
    'Ce rôle destiné aux devs permet d’effectuer les opérations les plus sensibles de la plateforme a des fins d’administration, ainsi que toutes les actions possibles a des fins de debugging.',
  broker:
    'Ce rôle destiné aux courtiers travaillant pour Orus permet d’effectuer les opérations nécessaires au jour le jour, pour les clients du courtier uniquement.',
  customer:
    'Ce rôle est destiné aux clients ayant signé un contrat avec Orus. Il permet d’effectuer les opérations nécessaires à la gestion du compte Orus.',
  prospect:
    'Ce rôle est destiné aux prospects qui n’ont pas encore signé de contrat avec Orus. Il permet d’effectuer les opérations nécessaires à la finalisation de la souscription.',
  anyone:
    'Ce rôle est destiné à tous les autres utilisateurs, et permet de démarrer une souscription, ainsi que de réaliser les opérations disponibles sans authentification.',
} as const
